import React from "react";
import { graphql } from "gatsby";
import { ProductJsonLd } from "gatsby-plugin-next-seo";

function Prodotto({ data, pageContext }) {
  let MyProduct = data.site.siteMetadata.products.filter((item) => {
    return item.slug === pageContext.slug;
  });

  return (
    <>
      <ProductJsonLd
        productName={MyProduct[0].name}
        images={[
          "https://casafraine.com/olioextraverginadioliva.jpg",
          "https://casafraine.com/formaggiodicapra.jpg",
        ]}
        description={MyProduct[0].description}
        brand="Casa Fraine"
        reviews={[
          {
            author: "Lello",
            datePublished: "2021-01-06T03:37:40Z",
            reviewBody:
              "Ho comprato questo prodotto in diverse occasioni, devo dire ottimo!",
            name: "Da provare",
            reviewRating: {
              bestRating: "5",
              ratingValue: "5",
              worstRating: "1",
            },
          },
        ]}
        aggregateRating={{
          ratingValue: "4.7",
          reviewCount: 1,
        }}
        offers={{
          price: "9.99",
          priceCurrency: "EUR",
          priceValidUntil: "2021-11-05",
          itemCondition: "https://www.casafraine.com/shop",
          availability: "www.casafraine.com/shop",
          url: `https://www.casafraine.com/shop/prodotto/${MyProduct[0].slug}`,
          seller: {
            name: "Casa Fraine",
          },
        }}
        mpn="925872"
      />
      <section>
        <div className="prodotto">
          <img
            width="400"
            height="400"
            className="prodotto__immagine"
            src={"http://www.casafraine.com/" + MyProduct[0].image}
            alt={MyProduct[0].name}
          />
          <div>
            <h2>{MyProduct[0].name}</h2>
            <p>{MyProduct[0].description}</p>
            <div className="Catalogue__item">
              <button
                href="#"
                className="Product snipcart-add-item"
                data-item-id={MyProduct[0].name}
                data-item-price={MyProduct[0].price}
                data-item-image={"https://casafraine.com/" + MyProduct[0].image}
                data-item-description={MyProduct[0].description}
                data-item-name={MyProduct[0].name}
                data-item-url={
                  "https://casafraine.com/shop/prodotto/" + MyProduct[0].slug
                }
              >
                compra TEST
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Prodotto;
export const query = graphql`
  query prodottoQuery($slug: String!) {
    site(siteMetadata: { products: { elemMatch: { slug: { eq: $slug } } } }) {
      siteMetadata {
        products {
          description
          image
          slug
          price
          id
          name
        }
      }
    }
  }
`;
